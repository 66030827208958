import React, { useState, useEffect, useContext } from 'react';
import { SeatSelectorContext } from '../';
import isSeatEqual from '../services/isSeatEqual';
import styles from '../styles.module.scss';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import deepContains from '../services/deepContains';
import formatSeatNumber from '../services/formatSeatNumber';

const Seat = ({
    reservations,
    identity,
    seat
}) => {
    const [
        selectedSeats,
        setSelectedSeats,
        isAdmin,
        numbering,
        calcSeatNumbers
    ] = useContext(SeatSelectorContext);

    const reservation = reservations.length > 0 ? reservations[0] : null;
    const initialStatus = reservation ? reservation.status : 'available';
    const seatSchema = {
        ...identity,
        seat: Number(seat.attributes.cr_seat),
        view: { ...identity.view, seat: seat.attributes.number }
    }
    const [status, setStatus] = useState(initialStatus);
    const [seatNumbers, setSeatNumbers] = useState('');
    const { disabled, handicap, invisible } = seat.attributes;

    const customStyles = reservation && reservation.customSeatType ? { background: reservation.seatColor, borderColor: reservation.seatColor } : {};

    useEffect(() => {
        if (!disabled && !invisible && !(isNaN(seatSchema.row) && isNaN(seatSchema.seat)) && status !== 'selected') {
            calcSeatNumbers(seatSchema, status);
        }
    }, [status]);

    useEffect(() => {
        if (status === 'blockout' || disabled) {
            setSeatNumbers(`x`);
            return;
        }

        if(!numbering) {
            setSeatNumbers('');
        } else {
            const formatted = formatSeatNumber(seatSchema, numbering);
            setSeatNumbers(formatted);
        }

    }, [numbering, status]);

    useEffect(() => {
        // validate my status against selections
        // this is necessary when an external component has changed the selection state
        const alreadySelected = selectedSeats.filter((s) => isSeatEqual(s, seatSchema));

        if (status !== 'selected' && alreadySelected.length > 0) {
            setStatus('selected');
        } else if (status === 'selected' && alreadySelected.length === 0) {
            setStatus(initialStatus);
        }
    }, [selectedSeats, status])


    const handleSelection = () => {
        if (disabled) {
            return;
        }

        if (status === 'reserved' || status === 'requested') {
            confirmAlert({
                title: 'Seat Already Reserved',
                message:
                    'You cannot reserve this seat, someone else has already taken it. Please choose a different seat.',
                buttons: [{ label: 'Ok' }],
                closeOnClickOutside: true
            });
        } else if (status === 'blockout') {
            confirmAlert({
                title: 'Seat Unavailable',
                message:
                    'You cannot reserve this seat, an administrator has made it unavailable.',
                buttons: [{ label: 'Ok' }],
                closeOnClickOutside: true
            });
        } else {
            setSelectedSeats(
                deepContains(selectedSeats, seatSchema)
                    ? selectedSeats.filter((s) => !isSeatEqual(s, seatSchema))
                    : [...selectedSeats, seatSchema]
            );
            setStatus(status === 'available' ? 'selected' : 'available');
        }
    };

    const returnRequestedStyle = () => {
        if (isAdmin === false) {
            return styles.seatReserved;
        } else if (isAdmin === true) {
            return styles.seatRequested;
        } else {
            return '';
        }
    };

    return (
        <div
            className={`${styles.outerSeat} ${
                status === 'selected' ? styles.seatSelected : ''
            }
            ${disabled ? styles.disabled : ''}
            ${invisible ? styles.invisible : ''}`}
            onClick={() => handleSelection()}
        >
            <div
                style={status === 'selected' ? {} : customStyles}
                className={`
                ${styles.seat}
                ${status === 'selected' ? styles.selected : ''}
                ${status === 'reserved' ? styles.seatReserved : ''}
                ${status === 'blockout' ? styles.seatBlockout : ''}
                ${status === 'requested' ? returnRequestedStyle() : ''}
            `}>
                {handicap && (
                    <svg
                        style={{ position: 'absolute', width: '15px' }}
                        aria-hidden='true'
                        focusable='false'
                        data-icon='wheelchair'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                    >
                        <path
                            fill='currentColor'
                            d='M496.101 385.669l14.227 28.663c3.929 7.915.697 17.516-7.218 21.445l-65.465 32.886c-16.049 7.967-35.556 1.194-43.189-15.055L331.679 320H192c-15.925 0-29.426-11.71-31.679-27.475C126.433 55.308 128.38 70.044 128 64c0-36.358 30.318-65.635 67.052-63.929 33.271 1.545 60.048 28.905 60.925 62.201.868 32.933-23.152 60.423-54.608 65.039l4.67 32.69H336c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16H215.182l4.572 32H352a32 32 0 0 1 28.962 18.392L438.477 396.8l36.178-18.349c7.915-3.929 17.517-.697 21.446 7.218zM311.358 352h-24.506c-7.788 54.204-54.528 96-110.852 96-61.757 0-112-50.243-112-112 0-41.505 22.694-77.809 56.324-97.156-3.712-25.965-6.844-47.86-9.488-66.333C45.956 198.464 0 261.963 0 336c0 97.047 78.953 176 176 176 71.87 0 133.806-43.308 161.11-105.192L311.358 352z'
                        />
                    </svg>
                )}
                {seatNumbers}
            </div>
        </div>
    );
};

export default Seat;
