const removeEncoding = (string) => {
    if (!string) {
        return {};
    }

    if (typeof string === 'object') {
        string = JSON.stringify(string);
    }
    return JSON.parse(string.replace(/&quot;/g, '"'));
};

export default removeEncoding;
