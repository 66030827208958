const formatSeatNumber = (seat, numberingConfig) => {
    const getNumber = (location) => {
        let value;
        if(numberingConfig.view) {
            value = seat.view[location];
        } else {
            value = seat[location];
        }

        const finalParam = location === 'seat';
        // Add everything together, skipping the separator if it's the last element and using
        // a default separator if nothing is set
        return value && `${value}${finalParam ? '' : (numberingConfig.separator || "-")}`;
    }

    const sectionNum = numberingConfig.section ? getNumber('section') : ''
    const rowNum = numberingConfig.row ? getNumber('row'): '';
    const seatNum = numberingConfig.seat ? getNumber('seat') : '';

    return `${sectionNum}${rowNum}${seatNum}`;
}

export default formatSeatNumber;
