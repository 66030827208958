import deepContains from './deepContains';

const getMatchingReservations = (
    reservations,
    field,
    value,
    record = false
) => {
    if(record) {
        return reservations.filter((reservation) => deepContains(reservation.seats, record));
    } else {
        return reservations.filter((reservation) =>
                reservation.seats.filter((seat) => seat[field] === value)
                    .length > 0
            );
    }
};

export default getMatchingReservations;
