import React from 'react';
import Row from './Row';
import getMatchingReservations from '../services/getMatchingReservations';
import styles from '../styles.module.scss';
import removeEncoding from '../services/removeEncoding';

const Section = ({ reservations, identity, section }) => {
    const sectionStyles = removeEncoding(section.attributes.styles);
    const seatSchema = {
        ...identity,
        section: Number(section.attributes.cr_section),
        view: { ...identity.view, section: section.attributes.number }
    }
    const designLine = section.children[0].name === 'svg' ? section.children[0] : false;

    const decodeSVGAttributes = (attrs) => {
        const style = attrs.style ? JSON.parse(attrs.style) : {};
        return {
            ...attrs,
            style
        };
    };

    const circleSize = section.attributes.circle;

    if (designLine) {
        return (
            <div className='designLine' style={sectionStyles}>
                <svg {...designLine.attributes}>
                    {designLine.children.map((item, i) => {
                        if (item.name === 'rect') {
                            return React.createElement(
                                item.name,
                                {
                                    ...decodeSVGAttributes(item.attributes),
                                    key: i + '' + identity.section
                                },
                                item.value
                            );
                        }
                    })}
                    {designLine.children.map((item, i) => {
                        if (item.name === 'text') {
                            return React.createElement(
                                item.name,
                                {
                                    ...decodeSVGAttributes(item.attributes),
                                    key: i + '' + identity.section
                                },
                                item.value
                            );
                        }
                    })}
                </svg>
            </div>
        );
    }

    return (
        <div
            className={`section ${styles.section} ${section.attributes.cr_section}`}
            style={ sectionStyles }
        >
            {section.children.map((row) => (
                <Row
                    key={`row_${seatSchema.level}${seatSchema.section}${row.attributes.cr_row}`}
                    identity={seatSchema}
                    reservations={getMatchingReservations(
                        reservations,
                        'row',
                        Number(row.attributes.cr_row)
                    )}
                    circle={circleSize}
                    row={row}
                />
            ))}
        </div>
    );
};

export default Section;
