import React from 'react';
import Section from './Section';
import getMatchingReservations from '../services/getMatchingReservations';
import removeEncoding from '../services/removeEncoding';

const Level = ({ reservations, level }) => {
    const levelStyles = removeEncoding(level.attributes.styles);

    const seatSchema = {
        level: Number(level.attributes.cr_level),
        view: {
            level: level.attributes.name
        }
    };

    return (
        <div className={`level`} style={ levelStyles }>
            {level.children.map((section) => (
                <Section
                    key={`section_${seatSchema.level}${section.attributes.number}`}
                    identity={ seatSchema }
                    reservations={getMatchingReservations(
                        reservations,
                        'section',
                        Number(section.attributes.cr_section)
                    )}
                    circle={section.attributes.circle}
                    section={section}
                />
            ))}
        </div>
    );
};

export default Level;
