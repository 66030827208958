import React from 'react';
import Seat from './Seat';
import getMatchingReservations from '../services/getMatchingReservations';
import styles from '../styles.module.scss';
import removeEncoding from '../services/removeEncoding';

const Row = ({
    reservations,
    identity,
    circle, // "large" | "medium"
    row
}) => {
    const rowStyle = removeEncoding(row.attributes.styles);
    const seatSchema = {
        ...identity,
        row: Number(row.attributes.cr_row),
        view: { ...identity.view, row: row.attributes.name }
    }

    return (
        <div
            className={`row ${styles.row}${circle ? ` circle ${circle} ${styles[circle]}` : ''}`}
            style={rowStyle}
        >
            {row.children.map((seat) => (
                <Seat
                    key={`seat_${seatSchema.section}${seatSchema.row}${seat.attributes.cr_seat}`}
                    identity={seatSchema}
                    reservations={getMatchingReservations(
                        reservations,
                        'seat',
                        Number(seat.attributes.cr_seat),
                        reservations.length
                            ? {
                                    ...seatSchema,
                                    seat: Number(seat.attributes.cr_seat)
                                }
                            : false
                    )}
                    seat={seat}
                />
            ))}
        </div>
    );
};

export default Row;
